import axios from "axios";

const getApiDomain = () => {
  const origin = location.origin;
  switch (origin) {
    case "https://dev.muda-v2.com":
      return "https://dev-api.muda-v2.com";
    case "https://staging.muda-v2.com":
      return "https://staging-api.muda-v2.com";
    case "https://muda-v2.com":
    case "https://otc.muda.tech":
      return "https://api.muda-v2.com";
    default:
      return "http://127.0.0.1:8787";
    // return "https://dev-api.muda-v2.com";
  }
};

const signup = async (creds) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/signup`, creds);
};

const login = async (creds) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/signin`, creds);
};

const startResetPassword = async (email) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/start/reset/password`, email);
};

const resendConfirmationCode = async (email) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/resend/confirmationCode`, email);
};

const resetPassword = async ({ confirmationCode, newPassword, email }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/reset/password`, { confirmationCode, newPassword, email });
};

const confirmEmail = async (creds) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/confirm/email`, creds);
};

const uploadProfilePicture = async (file) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/uploadfile`, file);
};

const verifyToken = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/verify/token`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateMyKycProfile = async ({ profile, token }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/my/kyc/profile`, profile, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMyProfile = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/my/profile`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const startVerifyPhonenumber = async ({ token }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/verify/phone/start`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const verifyPhonenumber = async ({ token, code }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/verify/phone`,
    { otp: code },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const searchForProfile = async ({ token, criteria }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/search/profiles`,
    { criteria },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const createMessage = async ({ token, message, id }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/create/message`,
    { content: message, thread: id },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const createThread = async ({ token, name, participants }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/create/thread/group`,
    { name, participants },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const createIndividualThread = async ({ token, name, selectedProfileId }) => {
  const domain = getApiDomain();
  return axios.post(
    `${domain}/create/thread/individual`,
    { name, selectedProfileId },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const getThreads = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/my/threads`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMessages = async ({ token, id }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/thread/${id}/messages`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getRates = async () => {
  const domain = getApiDomain();
  return axios.get(`${domain}/rates`);
};

const getWallets = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/wallets`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const placeMarketTrade = async ({ token, order }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/markettrade`, order, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const placeOtcTrade = async ({ token, order }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/otctrade`, order, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getOtctrades = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/otctrades`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getPaymentMethods = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/paymentmethods`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addPaymentMethodBankAccount = async ({ paymentMethod, token }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/paymentmethods/bankaccount`, paymentMethod, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addPaymentMethodPhonenumber = async ({ paymentMethod, token }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/paymentmethods/phonenumber`, paymentMethod, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addPaymentMethodWalletAddress = async ({ paymentMethod, token }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/paymentmethods/walletaddress`, paymentMethod, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMarketplaceOrders = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/orders/marketplace`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const createRequest = async ({ token, request, orderId }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${orderId}/requests`, request, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getSentRequests = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/requests/sent`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getReceivedRequests = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/requests/received`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getFulfilledRequests = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/requests/fulfilled`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getAllRequests = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/requests`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const acceptRequest = async ({ token, request }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${request.order.id}/requests/${request.id}/accept`, request, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const rejectRequest = async ({ token, request }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${request.order.id}/requests/${request.id}/reject`, request, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getProofOfPayments = async ({ token, order, request }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/orders/${order.id}/requests/${request.id}/proofofpayments`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getOrderVerifiedProofOfPayments = async ({ token, order }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/orders/${order.id}/proofofpayments/verified`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const verifyProofOfPayment = async ({ token, order, request, pop }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/verify`, {
    id: pop.id
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const rejectProofOfPayment = async ({ token, order, request, pop }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/reject`, {
    id: pop.id
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addTransaction = async ({ transaction, token }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/manualTransaction`, transaction, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getSwapsReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/transactionsHistory/swaps`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getVolumeReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/volume`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getAllOrdersReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/counterPartyTransactions/allOrders`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getCompletedOrdersReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/counterPartyTransactions/completedOrders`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getOpenOrdersReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/counterPartyTransactions/openOrders`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getProfitAndLossReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/profitAndLoss`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getOutstandingBalanceReport = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/reports/counterPartyTransactions/outstandingBalance`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMyOrders = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/myorders`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const cancelOrder = async ({ token, order }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${order.id}/cancel`, order, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getPaymentOptions = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/paymentoptions`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getFeatureFlags = async () => {
  const domain = getApiDomain();
  return axios.get(`${domain}/featureflags`);
};

const api = {
  getApiDomain,
  signup,
  login,
  confirmEmail,
  verifyToken,
  updateMyKycProfile,
  uploadProfilePicture,
  getMyProfile,
  startVerifyPhonenumber,
  verifyPhonenumber,
  searchForProfile,
  createMessage,
  createThread,
  createIndividualThread,
  getThreads,
  getMessages,
  getRates,
  getWallets,
  rejectRequest,
  placeMarketTrade,
  placeOtcTrade,
  getOtctrades,
  getAllRequests,
  getPaymentMethods,
  addPaymentMethodBankAccount,
  addPaymentMethodPhonenumber,
  addPaymentMethodWalletAddress,
  getMarketplaceOrders,
  createRequest,
  getSentRequests,
  getReceivedRequests,
  acceptRequest,
  getProofOfPayments,
  verifyProofOfPayment,
  getFulfilledRequests,
  rejectProofOfPayment,
  addTransaction,
  getSwapsReport,
  getAllOrdersReport,
  getCompletedOrdersReport,
  getOpenOrdersReport,
  getFeatureFlags,
  startResetPassword,
  resetPassword,
  resendConfirmationCode,
  getVolumeReport,
  getOutstandingBalanceReport,
  getProfitAndLossReport,
  getMyOrders,
  getPaymentOptions,
  cancelOrder,
  getOrderVerifiedProofOfPayments
};

export default api;
